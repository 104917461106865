<template>
  <div class="flex h-screen bg-gray-200 font-roboto">
    <Sidebar/>

    <div class="flex-1 flex flex-col w-full">
      <Header
      :usuarioSesion="this.usuarioLogeado"
      />

      <main class="flex-1 overflow-x-hidden overflow-y-auto bg-blue-50">
        <div class="mx-auto py-8">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import Header from '@/components/dashboard/Header'
import Sidebar from '@/components/dashboard/Sidebar'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
    name: "DashboardLayout",
    setup() {
      const store = useStore();

      return {
        usuarioLogeado: computed(() => store.getters["auth/authUser"])
      }
    },
    components: { 
        Sidebar,
        Header
    }
}
</script>