<template>
  <div class="flex">
    <!-- Backdrop -->
    <div
      :class="sidebarOpen ? 'block' : 'hidden'"
      @click="closeSidebar"
      class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"
    ></div>
    <!-- End Backdrop -->

    <div
      :class="sidebarOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
      class="fixed z-30 inset-y-0 left-0 transition duration-300 transform bg-th-primary overflow-y-auto lg:translate-x-0 lg:static lg:inset-0"
    >
      <div class="flex items-center justify-center mt-8">
        <div class="flex items-center">
          <span class="text-white text-lg 2xl:text-2xl mx-2 font-normal uppercase">Vanguardia</span>
        </div>
      </div>

      <nav class="mt-10">
        <ul>
          <li>
            <router-link
              class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
              :class="[$route.name === 'Home' ? activeClass : inactiveClass]"
              to="/"
            >
              <faIcon
                icon="chart-pie"
                type="fas"
                class="text-white"
              >
              </faIcon>

              <span class="text-md 2xl:text-xl ml-4">Vista general</span>
            </router-link>
          </li>
          <li v-if="userRole == 'admin'">
            <a 
              class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
              :class="$route.name === 'Users' || $route.name === 'RegisterUser' ? activeClass : inactiveClass"
              href="#"
              @click.prevent="toggleMenu(1)"
            >
              <faIcon
                icon="users"
                type="fas"
                class="text-white"
              >
              </faIcon>
              <span class="text-md 2xl:text-xl ml-4">Usuarios</span>
            </a>
            <ul v-show="opcionDesplegada == 1" class="mx-6 text-left">
              <li>
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'Users' ? subActiveClass : inactiveClass"
                  to="/usuarios/listar"
                >
                  <span class="text-sm 2xl:text-lg">Ver listado</span>
                </router-link>
              </li>
              <li>
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="[$route.name === 'RegisterUser' ? subActiveClass : inactiveClass]"
                  to="/usuarios/registrar"
                >
                  <span class="text-sm 2xl:text-lg">Nuevo</span>
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="userRole == 'admin' || userRole == 'estandar'">
            <a 
              class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
              :class="$route.name === 'ListaCotizaciones' || $route.name === 'NuevaCotizacion' ? activeClass : inactiveClass"
              href="#"
              @click.prevent="toggleMenu(2)"
            >
              <faIcon
                icon="file-alt"
                type="fas"
                class="text-white"
              >

              </faIcon>
              <span class="text-md 2xl:text-xl ml-4">Cotizaciones</span>
            </a>
            <ul v-show="opcionDesplegada == 2" class="mx-6 text-left">
              <li>
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'ListaCotizaciones' ? subActiveClass : inactiveClass"
                  to="/cotizaciones/lista-cotizaciones"
                >
                  <span class="text-sm 2xl:text-lg">Ver listado</span>
                </router-link>
              </li>
              <li>
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="[$route.name === 'NuevaCotizacion' ? subActiveClass : inactiveClass]"
                  to="/cotizaciones/nueva-cotizacion"
                >
                  <span class="text-sm 2xl:text-lg">Nueva</span>
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <a 
              class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
              :class="$route.name === 'ListaOrdenesTrabajo' || $route.name === 'ListaAbonos' ? activeClass : inactiveClass"
              href="#"
              @click.prevent="toggleMenu(3)"
            >
              <faIcon
                icon="ticket-alt"
                type="fas"
                class="text-white"
              >
              </faIcon>
              <span class="text-md 2xl:text-xl ml-4">Órdenes de trabajo</span>
            </a>
            <ul v-show="opcionDesplegada == 3" class="mx-6 text-left">
              <li v-if="can('edit OT') || isAdmin" >
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'ListaOrdenesTrabajo' ? subActiveClass : inactiveClass"
                  to="/ordenes-trabajo/lista-ot"
                >
                  <span class="text-sm 2xl:text-lg">Ver listado</span>
                </router-link>
              </li>
              <li v-if="isEstandar || isAdmin">
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'ListaAbonos' ? subActiveClass : inactiveClass"
                  to="/ordenes-trabajo/lista-abonos"
                >
                  <span class="text-sm 2xl:text-lg">Abonos</span>
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="isEstandar || isAdmin">
            <a 
              class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
              :class="$route.name === 'ListaVentas' || $route.name === 'NuevaVenta' ? activeClass : inactiveClass"
              href="#"
              @click.prevent="toggleMenu(4)"
            >
              <faIcon
                icon="shopping-bag"
                type="fas"
                class="text-white"
              >
              </faIcon>
              <span class="text-md 2xl:text-xl ml-4">Bazar</span>
            </a>
            <ul v-show="opcionDesplegada == 4" class="mx-6 text-left">
              <li>
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'ListaVentas' ? subActiveClass : inactiveClass"
                  to="/ventas/lista-ventas"
                >
                  <span class="text-sm 2xl:text-lg">Ver ventas</span>
                </router-link>
              </li>
              <li>
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'NuevaVenta' ? subActiveClass : inactiveClass"
                  to="/ventas/nueva-venta"
                >
                  <span class="text-sm 2xl:text-lg">Nueva</span>
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin || isEstandar">
            <a 
              class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
              :class="$route.name === 'CajaDiaria' || $route.name === 'OT' || $route.name === 'op' ? activeClass : inactiveClass"
              href="#"
              @click.prevent="toggleMenu(5)"
            >
              <faIcon
                icon="file-invoice-dollar"
                type="fas"
                class="text-white"
              >
              </faIcon>
              <span class="text-md 2xl:text-xl ml-4">Reportes</span>
            </a>
            <ul v-show="opcionDesplegada == 5" class="mx-6 text-left">
              <li>
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'CajaDiaria' ? subActiveClass : inactiveClass"
                  to="/reportes/caja-diaria"
                >
                  <span class="text-sm 2xl:text-lg">Caja diaria</span>
                </router-link>
              </li>
              <li v-if="isAdmin">
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'OT' ? subActiveClass : inactiveClass"
                  to="/reportes/ordenes-trabajo"
                >
                  <span class="text-sm 2xl:text-lg">OTs con saldo</span>
                </router-link>
              </li>
              <li v-if="isAdmin">
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'ReporteInventarioOT' ? subActiveClass : inactiveClass"
                  to="/reportes/reporte-inventario-ot"
                >
                  <span class="text-sm 2xl:text-lg">Inventario OT</span>
                </router-link>
              </li>
              <li v-if="isAdmin">
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'ReporteInventarioBazar' ? subActiveClass : inactiveClass"
                  to="/reportes/reporte-inventario-bazar"
                >
                  <span class="text-sm 2xl:text-lg">Inventario bazar</span>
                </router-link>
              </li>
              <li v-if="isAdmin">
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'ReporteTributario' ? subActiveClass : inactiveClass"
                  to="/reportes/reporte-tributario"
                >
                  <span class="text-sm 2xl:text-lg">Reporte tributario</span>
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a 
              class="flex items-center duration-200 mt-4 py-2 px-6 border-l-4"
              :class="$route.name === 'OTsAnuladas' || $route.name === 'AbonosAnulados' || $route.name === 'ventasAnuladas' ? activeClass : inactiveClass"
              href="#"
              @click.prevent="toggleMenu(6)"
            >
              <faIcon
                icon="trash-alt"
                type="fas"
                class="text-white"
              >
              </faIcon>
              <span class="text-md 2xl:text-xl ml-4">Papelera</span>
            </a>
            <ul v-show="opcionDesplegada == 6" class="mx-6 text-left">
              <li>
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'OTsAnuladas' ? subActiveClass : inactiveClass"
                  to="/papelera/ots"
                >
                  <span class="text-sm 2xl:text-lg">OTs</span>
                </router-link>
              </li>
              <li>
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'AbonosAnulados' ? subActiveClass : inactiveClass"
                  to="/papelera/abonos"
                >
                  <span class="text-sm 2xl:text-lg">Abonos</span>
                </router-link>
              </li>
              <li>
                <router-link
                  class="flex items-center duration-200 py-1 pl-10 border-l-4"
                  :class="$route.name === 'ventasAnuladas' ? subActiveClass : inactiveClass"
                  to="/papelera/ventas"
                >
                  <span class="text-sm 2xl:text-lg">Bazar</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import { can } from "@/utils/helpers"
import { mapGetters } from "vuex"

export default {
  name: "Sidebar",
  data() {
    return {
      usersCollapsed: true,
      cotizacionesCollapsed: true,
      ordenesTrabajoCollapsed: true,
      ventasCollapsed: true,
      reportesCollapsed: true,
      papeleraCollapsed: true,

      opcionDesplegada: 0
    }
  },
  computed: {
    sidebarOpen() {
      return this.$store.getters.sidebarOpen
    },
    ...mapGetters("auth", ["authUser", "userRole", "isAdmin", "isEstandar", "isMontaje"])
  },
  methods: {
    closeSidebar() {
      this.$store.dispatch("closeSidebar")
    },
    can(ability) {
      return can(this.authUser, ability)
    },
    toggleMenu(numeroOpcion) {
      if (numeroOpcion != this.opcionDesplegada) {
        this.opcionDesplegada = numeroOpcion;
      }
      else{
        this.opcionDesplegada = 0;
      }
    }
  },
  props: {
    activeClass: {
      type: String,
      default: "bg-gray-200 bg-opacity-25 text-gray-100 border-gray-100"
    },
    inactiveClass: {
      type: String,
      default: "border-th-primary hover:bg-gray-200 text-gray-100 hover:bg-gray-300 hover:bg-opacity-25 hover:text-gray-100"
    },
    subActiveClass: {
      type: String,
      default: "text-gray-100 hover:bg-gray-200 hover:bg-opacity-25"
    }
  }
}
</script>