<template>
  <header
    class="flex w-full justify-between items-center py-4 px-6 text-white color-fondo"
  >
    <div class="flex items-center h-full overflow-x-hidden">
      <div class="h-full">
        <button
        @click="toggleSidebar"
        class="text-gray-500 focus:outline-none lg:hidden h-full"
        >
          <svg
          class="h-6 w-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          >
            <path
            d="M4 6H20M4 12H20M4 18H11"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      
      <div class="flex h-full">
        <p class="pl-6 lg:pl-2 text-xs sm:text-sm self-center whitespace-no-wrap">
          {{ fecha }}
        </p>
      </div>
    </div>

    <div class="flex items-center">
      <div class="flex">
        <div class="flex items-center">
          <div class="px-3 tracking-widest text-xs sm:text-sm whitespace-no-wrap">
            {{ usuarioSesion.name }}
          </div>
        </div>
        <button
          @click="toggleDropdown"
          class="relative z-10 block h-10 w-10 rounded-full overflow-hidden focus:outline-none"
        >
          <faIcon
            icon="user-tie"
            type="fas"
            class="h-full w-full object-cover text-white text-xl"
            >
          </faIcon>
        </button>

        <div
          v-show="dropdownOpen"
          @click="dropdownOpen = false"
          class="fixed inset-0 h-full w-full z-10"
        >
        </div>

        <div
          v-show="dropdownOpen"
          class="absolute right-0 mt-2 py-2 bg-white rounded-lg shadow-xl z-20 text-left border border-gray-200 whitespace-no-wrap"
        >
          <router-link
            to="/mi-perfil"
            class="duration-200 block px-6 py-2 text-md text-gray-700 hover:bg-blue-500 hover:text-white"
            >
              <faIcon
              icon="address-card"
              type="fas"
              class="text-md mr-2"
              >
              </faIcon>
              Mi perfil
            </router-link
          >
          <router-link
            to="/mi-clave"
            class="duration-200 block px-6 py-2 text-md text-gray-700 hover:bg-blue-500 hover:text-white"
            >
              <faIcon
              icon="key"
              type="fas"
              class="text-md mr-2"
              >
              </faIcon>
              Cambiar contraseña
            </router-link
          >
          <a
            href="#"
            class="duration-200 block px-6 py-2 text-md text-gray-700 hover:bg-blue-500 hover:text-white"
            @click="logout"
            >
              <faIcon
              icon="sign-out-alt"
              type="fas"
              class="text-md mr-2"
              >
              </faIcon>
              Cerrar sesión
            </a
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import store from "@/store/index"
import moment from "moment"

export default {
    name: "Header",
    data(){
        return {
            dropdownOpen: false,
            fechaActual: ''
        }
    },
    computed: {
      sidebarOpen() {
        return store.getters.sidebarOpen
      },
      fecha: function() {
        return this.fechaActual;
      }
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen
        },
        toggleSidebar() {
          store.dispatch("toggleSidebar")
        },
        logout() {
          store.dispatch("auth/logout")
        }
    },
    mounted() {
      moment.locale('es');
      this.fechaActual = moment().format('LL');

      setInterval(() => {
			this.fechaActual = moment().format('LL');
		}, 1000);
    },
    props: {
      usuarioSesion: {
        type: Object,
        default: null
      }
    }
}
</script>

<style>
  .color-fondo {
    background-color: rgb(39, 77, 134);
  }
</style>